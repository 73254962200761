.container-with-sidenav {
    display: flex;
  }
  
  .machine-title{
    margin-top: 2rem;
  }

  .content {
    width: calc(100% - 250px); /* Assuming the sidenav is 250px wide */
    margin-left: 250px;
  }
  
  .summary-tile-row{
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  
  .summary-tile {
    background-color: #f4f4f4; /* Example color */
  }
  
 
  .title-toggle-row {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center items vertically */
  }
  
  @media (max-width: 640px) {
    .container-with-sidenav {
      flex-direction: column;
    }
  
    .content {
      width: 100%;
      margin-left: 0;
    }
  
    .title-toggle-row {
      flex-direction: column;
      align-items: flex-start;
    }
  }