.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: auto; /* Adjust the width as needed */
    min-width: 20rem;
  }
  