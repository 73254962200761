.header-logo {
    position: absolute;
    right: 16px; /* Adjust as needed */
    top: 50%;
    transform: translateY(-50%);
    /* Add more styling for the logo as needed */
  }
  
  /* Responsive adjustments if necessary */
  @media (max-width: 640px) {
    .header-logo {
      right: 8px; /* Adjust for smaller screens */
    }
  }